.footer {
  display: flex;
  position: fixed;
  width: 100%;
  background-color: #DAE5D0;
  color: #417D7A;
  bottom: 0;
  z-index: 100;
  align-items: center;
  justify-content: center;
  padding-top: 1%;
  padding-bottom: 1%;
  .footer-info {
    a {
      text-decoration: none;
      color: black;
    }
  }
}