.card-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);;
  padding: 2%;
  text-align: center;
  margin: 5%;

  .card-item-icon {

  }

  .card-body {
  }
}