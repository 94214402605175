.NavBar {
  display: flex;
  height: 10%;
  width: 100%;
  background-color: #417D7A;
  color: #EDE6DB;
  position: sticky;
  top: 0;
  z-index: 100;
  align-items: center;


  .Header {
    margin-left: 2%;
    width: 20%;
  }

  .Header:hover {
    cursor: pointer;
  }

  .navbar-body {
    width: 70%;
    display: flex;
    justify-content: right;

    .navbar-body-item {
      margin-right: 2%;
    }
    .navbar-body-item:hover {
      cursor: pointer;
    }
  }
}