.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 5%;

  .contact-container-body {
    display: flex;
    flex-direction: column;
    .contact-info {
      display: flex;
      align-items: center;
      font-size: 25px;
      svg {
        margin-right: 10px;
      }
    }
  }
}