.LandingPage {
  display: flex;
  height: 90%;

  .landing-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .landing-text {
    background-color: white;
    position: absolute;
    left: 30%;
    top: 25%;
    right: 25%;
    padding: 10px;
    font-size: 20px;
    border-radius: 20px;
    text-align: center;
  }

  @media(max-width: 600px) {
    p {
      font-size: 16px;
    }
  }
}