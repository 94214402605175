.personal-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
  padding-top: 5%;

  h1 {
    margin-bottom: 3%;
  }

  .personal-photo {
    img {
      border: 2px solid black;
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
  }

  .personal-header {
    text-align: center;
  }

  .personal-text {
    width: 50%;
    font-family: 'Roboto", "Helvetica", "Arial", sans-serif', serif;
    font-weight: 300;
    color: #999;
    text-align: center;
  }

  .bold-pairs {
    display: flex;
    justify-content: center;

    .key {
      font-weight: bold;
    }

    .text {
      margin-left: 5px;
    }
  }
}