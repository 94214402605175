.working-areas {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  width: 100%;

  .header {
    display: flex;
    justify-content: center;
  }

  .body {
    margin-top: 2%;
    justify-content: space-around;

    .wrapper {
      max-width: 60%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 5fr));
    }
  }
}